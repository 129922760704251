<template>
  <div>
    <v-toolbar
      class="primary"
      short
    >
      <v-app-bar-nav-icon style="color:white;"></v-app-bar-nav-icon>

      <v-toolbar-title style="color:white;">
        Property List
      </v-toolbar-title>
      <v-spacer />
      <v-text-field
        v-model="searchQuery"
        solo
        single-line
        dense
        hide-details
        placeholder="Search for Address"
        clearable
        class="shrink"
      ></v-text-field>
      <span style="color:white;">&nbsp;&nbsp;Market:&nbsp;</span>
      <v-select
        v-model="marketSelected"
        :items="marketList"
        item-text="marketShortName"
        item-value="marketShortName"
        placeholder="Select Market"
        solo
        dense
        style="width:180px; height: 42px;"
        class="shrink"
        @change="onMarketChange"
      ></v-select>
      <span style="color:white;">
        &nbsp;&nbsp;Property Status:&nbsp;
      </span>
      <v-select
        v-model="statusSelected"
        :items="statusList"
        item-text="propertystatus"
        item-value="propertystatus"
        placeholder="Select Status"
        solo
        dense
        style="width:180px; height: 42px;"
        class="shrink"
        @change="onStatusChange"
      ></v-select>
    </v-toolbar>

    <v-card>
      <!-- table -->
      <v-data-table
        v-model="selectedTableData"
        :headers="tableColumns"
        :items="propertyListTable"
        :options.sync="options"
        :server-items-length="totalInvoiceListTable"
        :loading="loading"
        class="text-no-wrap"
        no-data-text="No records - select Market and Status fields"
        :footer-props="{
          showFirstLastPage: true,
          'items-per-page-text':'Items per page'
        }"
      >
        <template #[`item.listPrice`]="{item}">
          <span>{{ item.listPrice.toLocaleString("en-US") }}</span>
        </template>
        <template #[`item.onRedfinDate`]="{item}">
          <span>{{ item.onRedfinDate | luxonRelative({ style: "short" }) }} </span>
        </template>
        <template #[`item.redfinEstimate`]="{item}">
          <span>{{ item.redfinEstimate.toLocaleString("en-US") }}</span>
        </template>
        <template v-slot:[`item.controls`]="props">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                class="mx-2"
                fab
                x-small
                @click="onCreateComparisonClick(props.item)"
                v-on="on"
              >
                <v-icon
                  size="20"
                  color="primary"
                >
                  {{ icons.mdiTrendingUp }}
                </v-icon>
              </v-btn>
            </template>
            <span>Create Comparison</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                class="mx-2"
                fab
                x-small
                @click="onViewDetailsClick(props.item)"
                v-on="on"
              >
                <v-icon
                  size="20"
                  color="error"
                >
                  {{ icons.mdiEyeOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>View Details</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                class="mx-2"
                fab
                x-small
                @click="onWebViewDetailsClick(props.item)"
                v-on="on"
              >
                <v-icon
                  size="20"
                  color="success"
                >
                  {{ icons.mdiLink }}
                </v-icon>
              </v-btn>
            </template>
            <span>View Web</span>
          </v-tooltip>
        </template>
        <template v-slot:[`footer.page-text`]="items">
          {{ items.pageStart }} - {{ items.pageStop }} of {{ items.itemsLength }}
        </template>
      </v-data-table>
      <PropertyDetails
        v-model="showPropertyDetails"
        :data="propertyDataLocal"
      />
    </v-card>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import {
  mdiTrendingUp,
  mdiPlus,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiMinus,
  mdiLink,
} from '@mdi/js'

import { onUnmounted, ref } from '@vue/composition-api'

// store module
import store from '@/store'

// store module
import EventBus from '@/views/event-bus'
import propertyStore from './propertyStore'

// composition function
import usePropertyLista from './usePropertyLista'
import PropertyDetails from './details/PropertyDetails.vue'

// import PropertyDetails from './details/PropertyDetails.vue'

export default {
  components: {
    PropertyDetails,
  },
  data() {
    return {
      showPropertyDetails: false,
      propertyDataLocal: [],
    }
  },
  methods: {
    async onViewDetailsClick(item) {
      this.showPropertyDetails = true
      EventBus.$emit('refresh_property', item.id)
    },
    async onWebViewDetailsClick(item) {
      // const address = props.props.item.address.replace(/\s+/g, '-')
      // const city = props.props.item.city.replace(/\s+/g, '-')
      // const { state } = props.props.item
      // const { zip } = props.props.item
      // const urlzillow = `https://www.zillow.com/homes/${address},-${city},-${state},-${zip}`
      window.open(item.url, '_blank')

      // window.open(urlzillow, '_blank')
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, propertyStore)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      tableColumns,
      searchQuery,
      options,
      propertyListTable,
      statusFilter,
      totalInvoiceListTable,
      loading,
      selectedTableData,
      marketSelected,
      statusSelected,
    } = usePropertyLista()

    const selectedAction = ref('')
    const marketList = ref([])
    const propertiesTypeList = ref([])

    store.dispatch('app-invoice/fetchMarkets').then(response => {
      marketList.value = response.data
      const equipo = JSON.parse(localStorage.getItem('marketSelected'))
      marketSelected.value = equipo.value
    })

    store.dispatch('app-invoice/fetchTypes').then(response => {
      propertiesTypeList.value = response.data
      console.log(propertiesTypeList)
    })

    const onMarketChange = () => {
      // Set user's ability in localStorage for Access Control
      localStorage.setItem('marketSelected', JSON.stringify(marketSelected))
    }

    const statusList = ref(['Active', 'Closed'])

    const status = JSON.parse(localStorage.getItem('statusSelected'))
    if (status != null) {
      statusSelected.value = status.value
    }

    const onStatusChange = () => {
      // Set user's ability in localStorage for Access Control
      localStorage.setItem('statusSelected', JSON.stringify(statusSelected))
    }

    const propData = ref({
      propData: {
        id: null,
        propertyId: null,
        market: 'SIERRA VISTA',
        address: '',
        city: '',
        state: '',
        zip: '',
        listPrice: 0,
        onRedfin: '',
        latitude: 0.0,
        longitude: 0.0,
        propertyBeds: 0,
        propertyBaths: 0,
        propertySqrFeet: 0,
        propertySqrPrice: 0,
        propertyYearBuilt: 0,
        propertyLotSize: 0,
        propertyLotSize02: 0,
        propertyNotes: '',
        compPool: 0,
        compHoa: 0,
        compGarage: 0,
        compWaterfront: false,
        compHasview: false,
        compGuesthouse: false,
        compRv: false,
        compCarport: 0,
        compPropertyType: '',
        comparisonStatus: 'Active',
        filterpropType: '',
        filterSqrtMin: 100,
        filterSqrtMax: 3000,
        filterSqrt: [100, 3000],
        filterYearMin: 1950,
        filterYearMax: 2022,
        filterYear: [1950, 2022],
        filterWhitinMin: 0.0,
        filterWhitinMax: 1.0,
        filterWhitin: [0.0, 1.0],
        filterBedsMin: 1,
        filterBedsMax: 5,
        filterBeds: [1, 5],
        filterBathsMin: 1,
        filterBathsMax: 5,
        filterBaths: [1, 5],
        filterSoldwhitinMin: 1,
        filterSoldwhitinMax: 12,
        filterSoldwhitin: [1, 12],
        listPriceValue: 0.0,
        propertyBedsValue: 0.0,
        propertyBathsValue: 0.0,
        propertySqrFeetValue: 0.0,
        propertySqrPriceValue: 0.0,
        propertyLotSizeValue: 0.0,
        compPoolValue: 0.0,
        compHoaValue: 0.0,
        compGarageValue: 0.0,
        compBasementValue: 0.0,
        compWaterfrontValue: 0.0,
        compViewValue: 0.0,
        compGuesthouseValue: 0.0,
        compRvValue: 0.0,
        compCarportValue: 0.0,
      },
    })

    const onCreateComparisonClick = item => {
      Swal.fire({
        title: 'Are you sure?',
        text: 'This action will create a compare record for this property.',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, create record!',
      }).then(result => {
        if (result.isConfirmed) {
          const userData = JSON.parse(localStorage.getItem('userData'))

          const index = propertiesTypeList.value.findIndex(row => row.propertyType === item.propertyType)
          if (index !== -1) {
            propData.value.propData.compPropertyType = propertiesTypeList.value[index].typeGroup
          } else {
            propData.value.propData.compPropertyType = 'Single Family Residential'
          }

          propData.value.propData.usuarioRegistro = userData.email
          propData.value.propData.usuarioModifico = userData.email

          propData.value.propData.propertyId = item.id
          propData.value.propData.market = item.market
          propData.value.propData.address = item.address
          propData.value.propData.city = item.city
          propData.value.propData.state = item.state
          propData.value.propData.zip = item.zip
          propData.value.propData.listPrice = item.listPrice
          propData.value.propData.onRedfin = item.onRedfin
          propData.value.propData.latitude = item.latitude
          propData.value.propData.longitude = item.longitude
          propData.value.propData.propertyBeds = item.propertyBeds
          propData.value.propData.propertyBaths = item.propertyBaths
          propData.value.propData.propertySqrFeet = item.propertySqrFeet
          propData.value.propData.propertySqrPrice = item.propertySqrPrice
          propData.value.propData.propertyYearBuilt = item.propertyYearBuilt
          propData.value.propData.propertyLotSize = item.propertyLotSize
          propData.value.propData.propertyLotSize02 = item.propertyLotSize02
          propData.value.propData.propertyNotes = ''
          propData.value.propData.compPool = 0
          propData.value.propData.compHoa = 0
          propData.value.propData.compGarage = 0
          propData.value.propData.compWaterfront = false
          propData.value.propData.compHasview = false
          propData.value.propData.compGuesthouse = false
          propData.value.propData.compRv = false
          propData.value.propData.compCarport = 0

          // propData.value.propData.compPropertyType = item.propertyType
          propData.value.propData.comparisonStatus = 'Active'
          propData.value.propData.filterpropType = item.propertyType
          propData.value.propData.filterSqrtMin = item.propertySqrFeet - 500
          propData.value.propData.filterSqrtMax = item.propertySqrFeet + 500
          propData.value.propData.filterYearMin = item.propertyYearBuilt - 10
          propData.value.propData.filterYearMax = item.propertyYearBuilt + 10
          propData.value.propData.filterWhitinMin = 0
          propData.value.propData.filterWhitinMax = 0.5
          propData.value.propData.filterBedsMin = item.propertyBeds - 1
          propData.value.propData.filterBedsMax = item.propertyBeds + 1
          propData.value.propData.filterBathsMin = item.propertyBaths - 1
          propData.value.propData.filterBathsMax = item.propertyBaths + 1
          propData.value.propData.filterSoldwhitinMin = 0
          propData.value.propData.filterSoldwhitinMax = 12
          propData.value.propData.propertyNotes = item.parkingInfo

          // Garage o carport info
          if (item.parkingInfo.includes('# of Parking Spaces (Garage)')) {
            const words = item.parkingInfo.split(',')
            const words2 = words[0].split(':')
            const spaces = Number(words2[1])
            propData.value.propData.compGarage = spaces
            propData.value.propData.compCarport = 0
          }

          // Garage o carport info
          if (item.parkingInfo.includes('# of Parking Spaces (Carport)')) {
            const words = item.parkingInfo.split(',')
            const words2 = words[0].split(':')
            const spaces = Number(words2[1])
            propData.value.propData.compGarage = 0
            propData.value.propData.compCarport = spaces
          }

          store.dispatch('app-invoice/addPropComparison', propData.value.propData).then(() => {
            Swal.fire({
              text: 'Comparison Added Successfully!',
              toast: true,
              position: 'top-right',
              iconColor: 'white',
              icon: 'success',
              customClass: {
                popup: 'colored-toast',
              },
              timer: 1500,
              timerProgressBar: true,
              showConfirmButton: false,
            })

            // router.go(-1)
          })

          Swal.fire(
            'Created!',
            'The record is created, go to Property Comparison option to view.',
            'success',
          )
        }
      })
    }

    return {
      selectedTableData,
      totalInvoiceListTable,
      propertyListTable,
      selectedAction,
      tableColumns,
      searchQuery,
      statusFilter,
      options,
      loading,
      marketList,
      marketSelected,
      onMarketChange,
      statusSelected,
      onStatusChange,
      onCreateComparisonClick,
      statusList,

      // icons
      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiMinus,
        mdiLink,
      },
    }
  },
}
</script>

<style lang="scss">

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th{
    font-size: 12px;
    padding: 0 16px;
    min-width: 50px !important;
}

</style>
