import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPropertyList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/propertieslist/leerlista', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchProperty(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/propertieslist/leerregistro?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProperty(ctx, propertyData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/propertieslist/reciberegistro', { json: propertyData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPropertyWeb(ctx, propertywebData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/propertieslist/reciberegistroweb', { json: propertywebData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMarkets(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/market/leerlista', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/propertiestype/leerlista', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchStatus(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/propertieslist/leerstatuspropiedad', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addPropComparison(ctx, propData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/propertycomparison/reciberegistro', { json: propData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
