var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"primary",attrs:{"short":""}},[_c('v-app-bar-nav-icon',{staticStyle:{"color":"white"}}),_c('v-toolbar-title',{staticStyle:{"color":"white"}},[_vm._v(" Property List ")]),_c('v-spacer'),_c('v-text-field',{staticClass:"shrink",attrs:{"solo":"","single-line":"","dense":"","hide-details":"","placeholder":"Search for Address","clearable":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('span',{staticStyle:{"color":"white"}},[_vm._v("  Market: ")]),_c('v-select',{staticClass:"shrink",staticStyle:{"width":"180px","height":"42px"},attrs:{"items":_vm.marketList,"item-text":"marketShortName","item-value":"marketShortName","placeholder":"Select Market","solo":"","dense":""},on:{"change":_vm.onMarketChange},model:{value:(_vm.marketSelected),callback:function ($$v) {_vm.marketSelected=$$v},expression:"marketSelected"}}),_c('span',{staticStyle:{"color":"white"}},[_vm._v("   Property Status:  ")]),_c('v-select',{staticClass:"shrink",staticStyle:{"width":"180px","height":"42px"},attrs:{"items":_vm.statusList,"item-text":"propertystatus","item-value":"propertystatus","placeholder":"Select Status","solo":"","dense":""},on:{"change":_vm.onStatusChange},model:{value:(_vm.statusSelected),callback:function ($$v) {_vm.statusSelected=$$v},expression:"statusSelected"}})],1),_c('v-card',[_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.tableColumns,"items":_vm.propertyListTable,"options":_vm.options,"server-items-length":_vm.totalInvoiceListTable,"loading":_vm.loading,"no-data-text":"No records - select Market and Status fields","footer-props":{
        showFirstLastPage: true,
        'items-per-page-text':'Items per page'
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.listPrice",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.listPrice.toLocaleString("en-US")))])]}},{key:"item.onRedfinDate",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("luxonRelative")(item.onRedfinDate,{ style: "short" }))+" ")])]}},{key:"item.redfinEstimate",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.redfinEstimate.toLocaleString("en-US")))])]}},{key:"item.controls",fn:function(props){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.onCreateComparisonClick(props.item)}}},on),[_c('v-icon',{attrs:{"size":"20","color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrendingUp)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Create Comparison")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.onViewDetailsClick(props.item)}}},on),[_c('v-icon',{attrs:{"size":"20","color":"error"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("View Details")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.onWebViewDetailsClick(props.item)}}},on),[_c('v-icon',{attrs:{"size":"20","color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiLink)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("View Web")])])]}},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" of "+_vm._s(items.itemsLength)+" ")]}}],null,true),model:{value:(_vm.selectedTableData),callback:function ($$v) {_vm.selectedTableData=$$v},expression:"selectedTableData"}}),_c('PropertyDetails',{attrs:{"data":_vm.propertyDataLocal},model:{value:(_vm.showPropertyDetails),callback:function ($$v) {_vm.showPropertyDetails=$$v},expression:"showPropertyDetails"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }