<template>
  <v-dialog
    v-model="show"
    max-width="1400px"
    scrollable
  >
    <v-card
      class="app-invoice-editable"
    >
      <v-card-actions>
        <v-toolbar
          class="primary"
          short
        >
          <v-toolbar-title style="color:white;">
            Property Details
          </v-toolbar-title>
        </v-toolbar>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          small
          outlined
          @click.stop="show=false"
        >
          Return to List
        </v-btn>
      </v-card-actions>
      <v-divider></v-divider>
      <v-card-text>
        <v-container>
          <v-row
            no-gutters
          >
            <v-col
              cols="12"
              md="7"
            >
              <v-container>
                <v-row dense>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <label
                      dense
                      hide-details="auto"
                    >
                      Home Address:
                    </label>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.address"
                      v-uppercase
                      outlined
                      dense
                      placeholder="Street Address"
                      label="Street Address"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="propertyData.city"
                      v-uppercase
                      outlined
                      dense
                      placeholder="City"
                      label="City"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="propertyData.state"
                      v-uppercase
                      outlined
                      dense
                      placeholder="State"
                      label="State"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="1"
                  >
                  </v-col>

                  <v-col
                    cols="12"
                    md="2"
                  >
                  </v-col>

                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="propertyData.zip"
                      v-uppercase
                      outlined
                      dense
                      placeholder="Zip Code"
                      label="Zip Code"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="7"
                  >
                  </v-col>

                  <v-col
                    cols="12"
                    md="2"
                  >
                    <label
                      dense
                      hide-details="auto"
                    >
                      Home Info:
                    </label>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.listPrice"
                      v-uppercase
                      outlined
                      dense
                      placeholder="Price"
                      label="Price"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="propertyData.propertyBeds"
                      v-uppercase
                      outlined
                      dense
                      placeholder="Beds"
                      label="Beds"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="propertyData.propertyBaths"
                      v-uppercase
                      outlined
                      dense
                      placeholder="Baths"
                      label="Baths"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="1"
                  >
                  </v-col>

                  <v-col
                    cols="12"
                    md="2"
                  >
                  </v-col>

                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="propertyData.propertySqrFeet"
                      v-uppercase
                      outlined
                      dense
                      placeholder="Square Feet"
                      label="Square Feet"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="7"
                  >
                  </v-col>

                  <v-col
                    cols="12"
                    md="2"
                  >
                    <label
                      dense
                      hide-details="auto"
                    >
                      Home Details:
                    </label>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.propertyStatus"
                      v-uppercase
                      outlined
                      dense
                      placeholder="Status"
                      label="Status"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.propertyType"
                      v-uppercase
                      outlined
                      dense
                      placeholder="Property Type"
                      label="Property Type"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="1"
                  >
                  </v-col>

                  <v-col
                    cols="12"
                    md="2"
                  >
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.propertyHoaDues"
                      v-uppercase
                      outlined
                      dense
                      placeholder="HOA Dues"
                      label="HOA Dues"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.community"
                      v-uppercase
                      outlined
                      dense
                      placeholder="Community"
                      label="Community"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="1"
                  >
                  </v-col>

                  <v-col
                    cols="12"
                    md="2"
                  >
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.propertyHoaPeriod"
                      v-uppercase
                      outlined
                      dense
                      placeholder="HOA Period"
                      label="HOA Period"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.propertyLotSize"
                      v-uppercase
                      outlined
                      dense
                      placeholder="Lot Size"
                      label="Lot Size"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="1"
                  >
                  </v-col>

                  <v-col
                    cols="12"
                    md="2"
                  >
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.propertyYearBuilt"
                      v-uppercase
                      outlined
                      dense
                      placeholder="Year Built"
                      label="Year Built"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.mls"
                      v-uppercase
                      outlined
                      dense
                      placeholder="MLS"
                      label="MLS"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="1"
                  >
                  </v-col>

                  <v-col
                    cols="12"
                    md="2"
                  >
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.apn"
                      v-uppercase
                      outlined
                      dense
                      placeholder="APN"
                      label="APN"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="5"
                  >
                  </v-col>

                  <v-col
                    cols="12"
                    md="2"
                  >
                  </v-col>

                  <v-col
                    cols="12"
                    md="8"
                  >
                    <v-textarea
                      v-model="propertyData.parkingInfo"
                      v-uppercase
                      outlined
                      dense
                      placeholder="Parking Info"
                      label="Parking Info"
                      rows="3"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-textarea>
                  </v-col>

                  <v-col
                    cols="12"
                    md="1"
                  >
                  </v-col>

                  <v-col
                    cols="12"
                    md="2"
                  >
                  </v-col>

                  <v-col
                    cols="12"
                    md="8"
                  >
                    <v-textarea
                      v-model="propertyData.utilityInfo"
                      v-uppercase
                      outlined
                      dense
                      placeholder="Utility Info"
                      label="Utility Info"
                      rows="3"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-textarea>
                  </v-col>

                  <v-col
                    cols="12"
                    md="1"
                  >
                  </v-col>

                  <v-col
                    cols="12"
                    md="2"
                  >
                    <label
                      dense
                      hide-details="auto"
                    >
                      Price Insights:
                    </label>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.redfinEstimate"
                      v-uppercase
                      outlined
                      dense
                      placeholder="Redfin Estimate"
                      label="Redfin Estimate"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.propertySqrPrice"
                      v-uppercase
                      outlined
                      dense
                      placeholder="Price/Sq.Ft"
                      label="Price/Sq.Ft"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="1"
                  >
                  </v-col>

                  <v-col
                    cols="12"
                    md="2"
                  >
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.onRedfin"
                      v-uppercase
                      outlined
                      dense
                      placeholder="On Redfin"
                      label="On Redfin"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="propertyData.market"
                      v-uppercase
                      outlined
                      dense
                      placeholder="Market"
                      label="Market"
                      autocomplete="off"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="1"
                  >
                  </v-col>

                  <v-col
                    cols="12"
                    md="11"
                  >
                  </v-col>
                </v-row>
              </v-container>
            </v-col>

            <v-col
              cols="12"
              md="4"
            >
              <v-container>
                <v-row dense>
                  <v-col
                    cols="12"
                    md="11"
                  >
                    <label
                      dense
                      hide-details="auto"
                    >
                      Photo Slideshow:
                    </label>
                  </v-col>

                  <v-col
                    cols="12"
                    md="11"
                  >
                    <v-carousel
                      v-model="carousel"
                      cycle
                      hide-delimiters
                      :show-arrows="false"
                      dense
                    >
                      <v-carousel-item
                        v-for="(item,i) in items"
                        :key="i"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                      >
                        <v-img
                          :src="item.src"
                          contain
                          :max-height="600"
                        ></v-img>
                      </v-carousel-item>
                    </v-carousel>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>

<script>
import EventBus from '@/views/event-bus'
import axios from '@axios'

export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      carousel: 0, // like this
      items: [],
      propertyData: {
        id: null,
        mls: '',
        apn: '',
        url: '',
        latitude: 0,
        longitude: 0,
        address: '',
        zip: '',
        city: '',
        state: '',
        community: '',
        parkingInfo: '',
        propertyBaths: 0,
        propertyBeds: 0,
        propertyHoaDues: 0,
        propertyHoaPeriod: '',
        propertyLotSize: '',
        propertySqrFeet: 0,
        propertySqrPrice: 0,
        propertyStatus: '',
        propertyType: '',
        propertyYearBuilt: 0,
        market: '',
        onRedfin: '',
        redfinEstimate: '',
        listPrice: '',
        image01: '',
        image02: '',
        image03: '',
        image04: '',
        image05: '',
        utilityInfo: '',
        usuarioRegistro: 'lerg98@gmail.com',
        usuarioModifico: '',
      },
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  created() {
    EventBus.$on('refresh_property', this.onViewDetailsClick)
  },
  destroyed() {
    EventBus.$off('refresh_property', this.onViewDetailsClick)
  },
  methods: {
    async onViewDetailsClick(id) {
      this.id = id
      console.log(this.id)
      let result = null

      // eslint-disable-next-line no-await-in-loop
      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await axios.get(`/api/propertieslist/leerregistro?id=${id}`)
        result = response.data
      } catch (error) {
        console.log(error)
      }

      if (result) {
        this.propertyData = result
        const imageJSON = [
          {
            src: this.propertyData.image01,
          },
          {
            src: this.propertyData.image02,
          },
          {
            src: this.propertyData.image03,
          },
        ]
        this.items = imageJSON
      } else {
        console.log(result)
      }
    },
  },
}
</script>
