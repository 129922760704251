import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function usePropertyLista() {
  const propertyListTable = ref([])

  // Table Handlers
  const tableColumns = [
    { text: 'MLS', value: 'mls' },
    { text: 'Address', value: 'address' },
    { text: 'City', value: 'city' },
    { text: 'State', value: 'state' },
    { text: 'On Redfin', value: 'onRedfinDate' },
    { text: 'Price', value: 'listPrice' },
    { text: 'Estimate', value: 'redfinEstimate' },
    { text: 'Type', value: 'propertyType', width: '120px' },
    { text: 'Status', value: 'propertyStatus', width: '120px' },
    { text: 'Beds', value: 'propertyBeds' },
    { text: 'Baths', value: 'propertyBaths' },
    { text: 'DOM', value: 'propertyDOM' },
    { text: 'DTC', value: 'propertyDTC' },
    { text: '', value: 'controls' },
  ]

  const equipo = JSON.parse(localStorage.getItem('marketSelected'))
  const status = JSON.parse(localStorage.getItem('statusSelected'))

  const searchQuery = ref('')
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
    itemsPerPage: 15,
    market: equipo.value,
    estatus: status.value,
  })
  const totalInvoiceListTable = ref(0)
  const loading = ref(false)
  const statusFilter = ref(null)
  const selectedTableData = ref([])
  const marketSelected = ref('')
  const statusSelected = ref('')

  const fetchProperties = () => {
    store
      .dispatch('app-invoice/fetchPropertyList', {
        q: searchQuery.value,
        options: options.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { data, total } = response
        const filteredData = data
        propertyListTable.value = filteredData
        totalInvoiceListTable.value = total
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([searchQuery, statusFilter, options, marketSelected, statusSelected], () => {
    // start loading
    loading.value = true
    selectedTableData.value = []
    options.value.market = marketSelected
    options.value.estatus = statusSelected
    fetchProperties()
  })

  return {
    tableColumns,
    searchQuery,
    options,
    propertyListTable,
    statusFilter,
    totalInvoiceListTable,
    loading,
    selectedTableData,
    marketSelected,
    statusSelected,
    fetchProperties,
  }
}
